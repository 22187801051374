<template>
  <section>
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      class="border mb-0 mt-2"
      no-body
    >
      <template #header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="m-0">
            {{ $t('employee.managedAgenciesInfo') }}
          </h5>
          <div>
            <b-button
              variant="info"
              class="ml-1"
              :disabled="!isDirty"
              @click="saveHandle"
            >
              <span class="">{{ $t('employee.save') }}</span>
            </b-button>
          </div>
        </div>
      </template>
      <b-card-body class="p-2 pt-2">
        <!-- Filters -->
        <EmployeeEditSearchAgencies
          :agencies-filter.sync="agenciesFilter"
          @select-agency="addToList"
        />
        <!-- ANCHOR Table Content -->
        <b-table
          ref="refAgenciesListTable"
          class="rounded"
          bordered
          responsive
          show-empty
          :items="managedAgenciesItems"
          :fields="tableColumns"
          primary-key="id"
        >
          <!-- ANCHOR header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`employee.managedAgenciesTable.${data.label}`) }}
            </span>
          </template>
          <template #cell(agencyCode)="data">
            <b-link
              :to="{name: 'apps-agencies-edit', params: { id: data.item.id }}"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
            >
              {{ data.item.agencyCode }}
            </b-link>
          </template>

          <!-- ANCHOR Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap text-right">
              <b-button
                class="py-50 px-1"
                variant="flat-danger"
                @click="removeItemToList(data.item.id)"
              >
                <feather-icon
                  :id="`invoice-row-${data.item.id}-trash-icon`"
                  icon="TrashIcon"
                  class="cursor-pointer"
                  size="16"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BTable,
  BLink,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import router from '@/router'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import EmployeeEditSearchAgencies from '@employee/employees-edit/EmployeeEditSearchAgencies.vue'

export default {
  components: {
    EmployeeEditSearchAgencies,
    BButton,
    BCard,
    BCardBody,
    BTable,
    BLink,
  },
  props: {
    managedAgencies: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const toast = useToast()
    const agenciesFilter = ref()
    const isDirty = ref(false)

    const tableColumns = [
      { label: 'agencyCode', key: 'agencyCode' },
      { label: 'agencyName', key: 'agencyName' },
      { label: 'emailAddress', key: 'emailAddress' },
      { label: 'ownerPhoneNumber', key: 'ownerPhoneNumber' },
      { label: 'actions', key: 'actions' },
    ]

    const managedAgenciesItems = ref(cloneDeep(props.managedAgencies))

    const selectMode = 'range'
    const selected = []

    async function addToList(selectAgency) {
      this.$bvModal.show('modal-api-loading')
      await new Promise(resolve => setTimeout(resolve, 300))
      let checkDuplicate = false
      managedAgenciesItems.value.forEach(agency => {
        if (agency.agencyCode === selectAgency.agencyCode) {
          checkDuplicate = true
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('messagesList.addAgencyToListManagementDuplicated'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      if (!checkDuplicate) {
        managedAgenciesItems.value.push(selectAgency)
        isDirty.value = true
      }
      this.$bvModal.hide('modal-api-loading')
    }

    function removeItemToList(id) {
      const index = managedAgenciesItems.value.findIndex(agc => agc.id === id)
      if (index > -1) {
        managedAgenciesItems.value.splice(index, 1)
        isDirty.value = true
      }
    }

    function saveHandle() {
      this.$bvModal.show('modal-api-loading')
      const payload = {
        agencyIds: managedAgenciesItems.value.map(item => item.id),
      }
      store.dispatch('app-employee/assignAgencies', { id: Number(router.currentRoute.params.id), payload })
        .then(() => {
          isDirty.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('messagesList.addAgencyToListManagementSuccess'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('messagesList.addAgencyToListManagementError'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.response.data.message || e,
            },
          })
        })
        .finally(() => { this.$bvModal.hide('modal-api-loading') })
    }

    return {
      agenciesFilter,
      tableColumns,
      selectMode,
      selected,
      addToList,
      removeItemToList,
      saveHandle,
      managedAgenciesItems,
      isDirty,
    }
  },
}
</script>

<style lang='scss'>@import '@core/scss/vue/libs/vue-select.scss';</style>
