<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label-for="created-by"
        >
          <v-select
            id="agencies-select"
            v-model="selectedAgency"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="agenciesOptions"
            label="agencyName"
            searchable
            :clearable="false"
            :filterable="false"
            :value="agenciesFilter"
            :loading="isLoading"
            :placeholder="$t('placeholderSelect')"
            :reduce="val => val"
            @open="handleOpenAgencies"
            @search="handleSearchAgencies"
            @input="(val) => $emit('update:agenciesFilter', val)"
          >
            <template #selected-option="{agencyName, agencyCode}">
              <div class="w-100 d-flex justify-content-between">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ agencyCode }} / {{ agencyName }}
                </span>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template v-slot:option="{agencyName, agencyCode}">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ agencyCode }} / {{ agencyName }}
                  </span>
                </b-col>
              </b-row>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

import api from '@/api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    vSelect,
  },
  props: {
    agenciesFilter: {
      type: [Object, null],
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const agenciesOptions = ref([])
    const isLoading = ref(false)
    const agenciesOptionsDefault = ref([])
    const selectedAgency = ref()

    const fetchAgencies = async (search = '') => {
      agenciesOptions.value = []
      isLoading.value = true
      try {
        const data = await api.agencies.fetchAgencies({ searchText: search })
        const agencyList = data?.data?.items.filter(agc => agc.id !== 1000000) // list filter MAMA
        if (data && !search) {
          agenciesOptionsDefault.value = agencyList
        }
        agenciesOptions.value = agencyList || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    function handleOpenAgencies() {
      if (agenciesOptionsDefault.value.length === 0) {
        fetchAgencies()
      } else {
        agenciesOptions.value = agenciesOptionsDefault.value
      }
    }

    function selectedItem(selectAgency) {
      if (selectAgency) {
        emit('select-agency', selectAgency)
      }
      setTimeout(() => {
        selectedAgency.value = undefined
      }, 0)
    }

    const handleSearchAgencies = debounce(search => {
      if (search) fetchAgencies(search)
    }, 500)

    watch(selectedAgency, value => {
      selectedItem(value)
    })

    return {
      agenciesOptions,
      selectedAgency,
      handleSearchAgencies,
      fetchAgencies,
      isLoading,
      handleOpenAgencies,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.w-10{
  width: 10%;
}

.w-15{
  width: 15%;
}
</style>

<style lang="scss" scoped>
#agencies-select{
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100%;
  }
}
</style>
