var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "created-by"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "agencies-select",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.agenciesOptions,
      "label": "agencyName",
      "searchable": "",
      "clearable": false,
      "filterable": false,
      "value": _vm.agenciesFilter,
      "loading": _vm.isLoading,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "open": _vm.handleOpenAgencies,
      "search": _vm.handleSearchAgencies,
      "input": function input(val) {
        return _vm.$emit('update:agenciesFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var agencyName = _ref.agencyName,
          agencyCode = _ref.agencyCode;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(agencyCode) + " / " + _vm._s(agencyName) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref2) {
        var loading = _ref2.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function fn(_ref3) {
        var agencyName = _ref3.agencyName,
          agencyCode = _ref3.agencyCode;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "9"
          }
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(agencyCode) + " / " + _vm._s(agencyName) + " ")])])], 1)];
      }
    }]),
    model: {
      value: _vm.selectedAgency,
      callback: function callback($$v) {
        _vm.selectedAgency = $$v;
      },
      expression: "selectedAgency"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }