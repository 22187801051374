var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c(_vm.employeeData === undefined ? 'div' : 'b-card', {
    tag: "component"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.employeeData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('employee.errFetch')) + " ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" " + _vm._s(_vm.$t('employee.errFindId_1')) + " "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-employees-list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('employee.errFindId_2')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('employee.errFindId_3')) + " ")], 1)]), _vm.employeeData ? _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "UserIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('employee.information')))])];
      },
      proxy: true
    }], null, false, 2679435133)
  }, [_c('employees-edit-tab-account', {
    attrs: {
      "employee-data": _vm.employeeData
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "UsersIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('employee.customers')))])];
      },
      proxy: true
    }], null, false, 3874299765)
  }, [_c('employees-edit-tab-customer', {
    attrs: {
      "employee-data": _vm.employeeData
    }
  })], 1), _c('b-tab', {
    attrs: {
      "disabled": !['SE', 'BE', 'KTV'].includes(_vm.employeeData.type)
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "ListIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('employee.agencies')))])];
      },
      proxy: true
    }], null, false, 3744285395)
  }, [_c('employees-edit-tab-agencies', {
    attrs: {
      "managed-agencies": _vm.employeeData.managedAgencies.map(function (item) {
        return item.agency;
      })
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }