<template>
  <section>
    <!-- Table Container Card -->
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      class="border mb-0 mt-2"
      no-body
      style="max-height: 70vh"
    >
      <template #header>
        <h6 class="m-0">
          Thông tin các khách hàng phụ trách
        </h6>
      </template>

      <b-card-body class="p-2 pt-2">
        <!-- Table Content -->
        <b-table
          ref="refEmployeeListTable"
          class="rounded"
          bordered
          responsive
          show-empty
          :items="employeeData.customers"
          :fields="tableCustomers"
          primary-key="id"
        >
          <template #cell(index)="data">
            <span class="font-weight-bold">{{ data.index + 1 }}</span>
          </template>

          <template #cell(information)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="40"
                  :src="data.item.avatar"
                  :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
                  :variant="`light-${resolveEmployeeTypeVariant(data.item.type)}`"
                  :to="{
                    name: 'apps-customers-edit',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <div class="d-flex flex-column justify-content-center align-items-start">
                <h5 class="mb-50">
                  <b-link
                    :to="{
                      name: 'apps-customers-edit',
                      params: { id: data.item.id },
                    }"
                    class="font-weight-bold d-block text-nowrap text-info"
                  >
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </b-link>
                </h5>
                <small class="text-dark d-flex justify-content-start align-items-center">
                  <feather-icon
                    size="14"
                    icon="MailIcon"
                    class="mr-1"
                  />
                  <span class="font-weight-bold">{{ data.item.emailAddress }} </span>
                </small>
              </div>
            </b-media>
          </template>

          <template #cell(birthDay)="data">
            <span class="font-weight-bold">{{ data.item.birthDay ? formatDateMonth(data.item.birthDay) : 'no information'
            }}</span>
          </template>
        </b-table>

      <!-- Table Footer -->
      <!-- <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="2"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} out of
              {{ employeeData.customers.length }} customers</span>
          </b-col>
          <b-col
            cols="12"
            sm="8"
            class="
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmployees"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div> -->
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardBody,
  BLink,
  BMedia,
  BTable,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'

import {
  genderOptions,
  sizePerPageOptions,
  statusOptions,
  typeOfEmployeeOptions,
} from '@/constants/selectOptions'
import store from '@/store'

import { avatarText, dateTime, formatDateMonth } from '@core/utils/filter'

import employeeStoreModule from '../employeeStoreModule'
import useEmployeeHandle from '../useEmployeeHandle'

export default {
  components: {
    // BMediaBody,
    // BMediaAside,
    // BFormCheckbox,
    BCard,
    BCardBody,
    // BRow,
    // BCol,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    // BPagination,
    // BFormInput,
    // BInputGroup,

    // vSelect,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) {
      store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
      }
    })

    function changeActive(employee) {
      if (employee.isActive) {
        this.updateActive(employee.id)
      } else {
        this.updateDeactive(employee.id)
      }
    }

    const {
      fetchEmployeesByFilter,
      deleteEmployee,
      tableColumns,
      sizePerPage,
      currentPage,
      totalEmployees,
      dataMeta,
      sortBy, isSortDirDesc,
      refEmployeeListTable,
      refetchData, clearFilter,
      updateDeactive,
      updateActive,
      bulkDeleteEmployees,

      // UI
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeTitle,
      resolveEmployeeIsActiveVariant,

      // Extra Filters
      typeFilter,
      genderFilter,
      searchTextFilter,
      statusFilter,
      createdByFilter,

      // Customer
      tableCustomers,

    } = useEmployeeHandle()

    return {
      // Sidebar
      changeActive,
      updateDeactive,
      updateActive,
      fetchEmployeesByFilter,
      deleteEmployee,
      bulkDeleteEmployees,

      // confirmDelete,
      dateTime,
      tableColumns,
      totalEmployees,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refEmployeeListTable,
      refetchData,
      clearFilter,

      // Params
      sizePerPage, // to API: size
      currentPage, // to API: page

      // Filter
      avatarText,

      // UI
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeTitle,
      resolveEmployeeIsActiveVariant,

      // select Options
      typeOfEmployeeOptions,
      genderOptions,
      statusOptions,
      sizePerPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,
      searchTextFilter,
      statusFilter,
      createdByFilter,

      // Customer
      tableCustomers,
      formatDateMonth,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

// .dark-layout .custom-switch .custom-control-input:not(:checked)~.custom-control-label:before {
//   background-color: #ea5455; // same danger color of bootstap
// }
</style>
