var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "border mb-0 mt-2",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" Thông tin các khách hàng phụ trách ")])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "p-2 pt-2"
  }, [_c('b-table', {
    ref: "refEmployeeListTable",
    staticClass: "rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "show-empty": "",
      "items": _vm.employeeData.customers,
      "fields": _vm.tableCustomers,
      "primary-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "40",
                  "src": data.item.avatar,
                  "text": _vm.avatarText("".concat(data.item.firstName, " ").concat(data.item.lastName)),
                  "variant": "light-".concat(_vm.resolveEmployeeTypeVariant(data.item.type)),
                  "to": {
                    name: 'apps-customers-edit',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-start"
        }, [_c('h5', {
          staticClass: "mb-50"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "to": {
              name: 'apps-customers-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.firstName) + " " + _vm._s(data.item.lastName) + " ")])], 1), _c('small', {
          staticClass: "text-dark d-flex justify-content-start align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "14",
            "icon": "MailIcon"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.item.emailAddress) + " ")])], 1)])])];
      }
    }, {
      key: "cell(birthDay)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.item.birthDay ? _vm.formatDateMonth(data.item.birthDay) : 'no information'))])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }